import { MainLayoutContainer } from "../components/organisms/homepage/MainLayoutContainer";
import { ButtonLarge, Variant } from "../components/atoms/Buttons/ButtonLarge";
import { ReactComponent as Plus } from "../assets/icons/Plus.svg";

import { useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";

import {
  Table,
  TableProps,
} from "../components/organisms/project/create/schema/Table";
import { RulesGroup } from "../components/organisms/project/rules/rulesgroup/RulesGroup";
import { ActionType } from "../components/organisms/project/rules/rulesgroup/RuleItemAction";
import { Variant as ButtonSmallVariant } from "../components/atoms/Buttons/ButtonSmall";
import { Header } from "../components/atoms/text/Header";
import { useAuth0 } from "@auth0/auth0-react";
import {
  ApiKeyData,
  ApiModal,
  ApiToken,
} from "../components/organisms/api/ApiModal";
import { LoadingSpinner } from "../components/molecules/LoadingSpinner/LoadingSpinner";
import { Body1 } from "../components/atoms/text/Body1";

type ApiTableDatum = ApiKeyData & {
  id: string;
  name: string;
  scopes: string[];
  expiration: string;
  revoked: boolean;
};

const udAPIKeyURL = import.meta.env.VITE_UDAPI_URL;

const DeleteCell = ({
  id,
  onDelete,
}: {
  id: string;
  onDelete: () => Promise<void>;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <RulesGroup.ItemAction
      actionType={ActionType.Delete}
      ruleKey={`delete-${id}`}
      variant={ButtonSmallVariant.Tertiary}
      label="Revoke"
      onAction={async () => {
        if (isLoading) return;
        await onDelete();
        setIsLoading(false);
      }}
      isActionLoading={isLoading}
    />
  );
};

export default function Api() {
  const [apiData, setApiData] = useState<ApiTableDatum[]>([]);
  const auth0 = useAuth0();

  const deleteApiKey = useCallback(
    async (id: string) => {
      const token = await auth0.getAccessTokenSilently();
      await fetch(`${udAPIKeyURL}/tokens/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "DELETE",
        mode: "cors",
      }).then((response) => response.json());
      setApiData((apiData) => apiData.filter((row) => row.id !== id));
    },
    [auth0]
  );

  const tableData: TableProps["data"] = useMemo(() => {
    return apiData
      .filter((row) => !row.revoked)
      .map((row): TableProps["data"][number] => [
        {
          key: row.id,
          value: row.id,
        },
        {
          key: `${row.id}${row.expiration}`,
          value: dayjs(row.expiration).format("HH:mm, DD/MM/YYYY"),
        },
        {
          key: `delete-${row.id}`,
          value: (
            <DeleteCell
              id={row.id}
              onDelete={async () => deleteApiKey(row.id)}
            />
          ),
        },
      ]);
  }, [apiData, deleteApiKey]);

  const [isLoadingList, setIsLoadingList] = useState(false);

  useEffect(() => {
    const fetchApiList = async () => {
      const token = await auth0.getAccessTokenSilently();

      fetch(`${udAPIKeyURL}/tokens`, {
        headers: {
          accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
        mode: "cors",
      })
        .then((response) => response.json())
        .then(setApiData)
        .finally(() => {
          setIsLoadingList(false);
        });
    };
    setIsLoadingList(true);
    fetchApiList();
  }, [auth0]);

  const [isGeneratingApiKey, setIsGeneratingApiKey] = useState(false);
  const [apiKey, setApiKey] = useState<string | null>(null);

  const generateApiKey = async () => {
    if (isGeneratingApiKey) return;

    setIsGeneratingApiKey(true);
    const token = await auth0.getAccessTokenSilently();

    const scopes: string[] = await fetch(
      `${udAPIKeyURL}/scopes`,
      {
        headers: {
          accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
        mode: "cors",
      }
    ).then((response) => response.json());

    const apiToken: ApiToken = await fetch(
      `${udAPIKeyURL}/tokens`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          scopes,
          expiration_days: 30,
          name: "",
        }),
        method: "POST",
        mode: "cors",
      }
    ).then((response) => response.json());

    setApiData((apiData) => [
      ...apiData,
      {
        ...apiToken,
        name: "",
        revoked: false,
        scopes,
      },
    ]);

    setIsGeneratingApiKey(false);
    setApiKey(apiToken.key);
  };

  return (
    <>
      <MainLayoutContainer title="API keys">
        <Header variant="green">Manage your API keys</Header>
        <Body1>
          Create keys to work with our APIs. Remember to always keep them
          private!
        </Body1>
        <Body1 className="my-4">
          <ButtonLarge
            onClick={() => generateApiKey()}
            iconLeft={isGeneratingApiKey ? <LoadingSpinner /> : <Plus />}
            variant={Variant.Primary}
          >
            Generate new API Key
          </ButtonLarge>
        </Body1>

        {isLoadingList ? (
          <div className="flex justify-center">
            <div className="w-8">
              <LoadingSpinner />
            </div>
          </div>
        ) : (
          Boolean(tableData.length) && (
            <>
              <div className="overflow-x-auto">
                <Table headers={["ID", "Expires By", ""]} data={tableData} />
              </div>
              <p className="mt-4">
                <b>Use of an API key is billed to your account.</b>
              </p>
              <p>
                Don’t share it with others or expose it in client-side code.
              </p>
            </>
          )
        )}
      </MainLayoutContainer>
      <ApiModal
        onClose={() => setApiKey(null)}
        onSubmit={(_formData) => {
          setApiKey(null);
        }}
        apiKey={apiKey}
      />
    </>
  );
}
