import { Outlet, useNavigate } from "react-router-dom";
import { User, useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { LoadingSpinner } from "./components/molecules/LoadingSpinner/LoadingSpinner";
// import useSentry from "./hooks/useSentry";
import { Policies, PolicyModal } from "./components/organisms/user/PolicyModal";
import { useUpdatePolicies } from "./api/mutations/policies";
import { useEffect, useState } from "react";

const claims = "https://sigtech.com/jwt/claims";

type Auth0User = User &
  Record<
    typeof claims,
    {
      app_metadata: {
        consent: { api: Policies };
      };
    }
  >;

const App = () => {
  const { user, isLoading, getAccessTokenSilently, isAuthenticated } =
    useAuth0<Auth0User>();
  const navigate = useNavigate();

  const {
    data: updatePolicyResponse,
    trigger: updatePolicies,
    isMutating,
  } = useUpdatePolicies();

  const api = user?.[claims]?.app_metadata?.consent?.api ?? {
    privacyNotice: false,
    termsAndConditions: false,
  };

  const [termsAndConditions, setTermsAndConditions] = useState(
    api?.termsAndConditions
  );
  const [privacyNotice, setPrivacyNotice] = useState(api?.privacyNotice);

  useEffect(() => {
    if (updatePolicyResponse?.status?.toLowerCase() === "ok") {
      setPrivacyNotice(true);
      setTermsAndConditions(true);
      getAccessTokenSilently({ cacheMode: "off" });
    }
  }, [updatePolicyResponse?.status, isMutating, getAccessTokenSilently]);

  useEffect(() => {
    if (!user?.email_verified && isAuthenticated) {
      navigate("/verify");
    }
  }, [user?.email_verified, isAuthenticated, navigate]);

  if (isLoading) {
    return (
      <div className="flex justify-center mt-64 h-16">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      {user?.email_verified && (!privacyNotice || !termsAndConditions) ? (
        <PolicyModal
          onSubmit={(formData) => {
            updatePolicies({ body: JSON.stringify(formData) });
          }}
          disablePrimaryButton={isMutating}
          termsAndConditions={termsAndConditions}
          privacyNotice={privacyNotice}
        />
      ) : null}
      <Outlet />
    </>
  );
};

const ProtectedApp = withAuthenticationRequired(App);

export default ProtectedApp;
