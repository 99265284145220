import React from "react"
import { Link } from "react-router-dom"

/* Icons */
import { ReactComponent as HelpIcon } from "../../../../assets/icons/QuestionMark.svg"
import { ReactComponent as DiscordIcon } from "../../../../assets/icons/Discord.svg"
import { ReactComponent as TwitterIcon } from "../../../../assets/icons/Twitter.svg"

export const SupportSection: React.FC = (): JSX.Element => {
    return (
        <div className="p-6 flex flex-col w-full" style={{ maxWidth: 230}}>
            <div className="p-2 flex w-full justify-center items-center gap-x-2">
                <HelpIcon /><p className="m-0 [color:var(--neutral-05)] font-semibold text-sm"> Support</p>
            </div>
            <div className="p-6 w-full [background-color:var(--grey-02)] rounded-lg flex justify-between">
                <Link to="https://twitter.com/sigtechltd" target="_blank">
                    <TwitterIcon height="25" className="[fill:var(--neutral-05)]"/>
                </Link>
                <Link to="https://discord.gg/yfTqrm2ubz" target="_blank">
                    <DiscordIcon height="25" className="[fill:var(--neutral-05)]"/>
                </Link>
            </div>
        </div>
    )
}