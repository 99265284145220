
import { Key } from "swr";
import { MutationFetcher } from "swr/mutation";

export const API_ENDPOINT = import.meta.env.VITE_UDAPI_URL;

export type GetAuthToken = () => Promise<string>;

export const withAuthHeaders = async (
  getAuthToken: GetAuthToken,
  headers: RequestInit["headers"] = {}
): Promise<RequestInit["headers"]> => {
  const authToken = await getAuthToken();
  return {
    ...headers,
    Authorization: `Bearer ${authToken}`,
  };
};

export const swrFetcher = (
  url: string,
  getAuthToken: GetAuthToken,
  options?: RequestInit
) => {
  async () => {
    fetch(`${API_ENDPOINT}${url}`, {
      ...options,
      headers: await withAuthHeaders(getAuthToken),
    });
  };
};

type SwrMutater = <
  Data,
  ExtraArg extends Readonly<{
    path?: string;
    body?: BodyInit;
  }>
>(
  baseUrl: string,
  getAuthToken: GetAuthToken,
  options?: RequestInit
) => MutationFetcher<Data, Key, ExtraArg>;

export const swrMutater: SwrMutater =
  (baseUrl, getAuthToken, options = { method: "POST" }) => 
  async <
    Data,
    Key,
    ExtraArg extends Readonly<{
      path?: string;
      body?: BodyInit;
    }>
  >(
    _: Key,
    { arg }: { arg: ExtraArg }
  ) => {
    const url = `${API_ENDPOINT}${baseUrl}${arg.path ?? ""}`;
    const res = await fetch(url, {
      ...options,
      headers: await withAuthHeaders(getAuthToken, {
        ...options.headers,
        'Content-Type': 'application/json'
      }),
      body: arg?.body,
    });
    return res.json() as Promise<Data>;
  };
